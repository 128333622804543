export default function Footer() {
  return (
    <footer className="bg-secondary footer-height">
      <div className="p-4">
        <p className="text-center font-normal text-sm text-white">
          Copyright © 2024 | All rights reserved. Official Beer Trick
          Application.
        </p>
      </div>
    </footer>
  );
}
